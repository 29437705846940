import React, { FC, useState, useEffect } from "react";
import { Table, Button, notification, Modal, Select, Input } from "antd";
import { AuthTokenType, FranchiseeProps, Transactionprops } from "../utils/types";
import { useGetFranchisee, useGettransactions } from "../utils/hooks";
import { getAuthToken, gettransactions } from "../utils/functions";
import { transactionsupdateurls} from "../utils/network";
import axios from "axios";
import AddTransactionForm from "../components/AddTransactions";
import dayjs from 'dayjs';
const { Option } = Select;

const Transactions: FC = () => {


  const userRole = localStorage.getItem('token1');


  const [transactions, setTransactions] = useState<Transactionprops[]>([]);
  const [fetching, setFetching] = useState<boolean>(true);
  const [formVisible, setFormVisible] = useState(false);
  const [editingTransaction, setEditingTransaction] = useState<Transactionprops | null>(null);

  const [franchisee, setfranchisee] = useState<FranchiseeProps[]>([]);
  useGetFranchisee(setfranchisee, setFetching);
  const [selectedFranchisee, setSelectedFranchisee] = useState<string | null>(null); // State to hold selected franchisee
  const handleChangeFranchisee = (value: string) => {
    setSelectedFranchisee(value); // Update selected franchisee when filter changes
  };

  useGettransactions(setTransactions, setFetching, selectedFranchisee);

  useEffect(() => {
    gettransactions(setTransactions, setFetching, selectedFranchisee);
  }, [selectedFranchisee]);

  useEffect(() => {
    // When editingCategory changes, open the modal
    if (editingTransaction) {
      setFormVisible(true);
    }
  }, [editingTransaction]);

  const handleEdit = (record: Transactionprops) => {
    setEditingTransaction(record);
  };

  const handleDelete = (transactionid: number) => {
    Modal.confirm({
      title: "Confirm Deletion",
      content: "Are you sure you want to delete this supplier?",
      onOk: async () => {
        try {
          const headers = getAuthToken() as AuthTokenType;

          // Make a DELETE request to the API endpoint for deleting a supplier
          await axios.delete(`${transactionsupdateurls}/${transactionid}`, headers);

          // After successful deletion, fetch updated suppliers
          gettransactions(setTransactions, setFetching, selectedFranchisee);
        } catch (error) {
          // Handle error if the deletion fails
          notification.error({
            message: "Delete Operation Error",
          });
        }
      },
      onCancel: () => {
        // Do nothing if the user cancels the deletion
      },
      okText: "Yes",
      cancelText: "No",
    });
  };

  const onCloseWithoutEditing = () => {
    setEditingTransaction(null); // Reset editingCategory when form is closed without editing    
  };

  const getColumnFilterValues = (data: any[], key: string | string[]) => {
    const uniqueValues = new Set(data.map((item) => {
      if (Array.isArray(key)) {
        let value = item;
        for (const k of key) {
          value = value[k];
        }
        return value;
      } else {
        return item[key];
      }
    }));
    return Array.from(uniqueValues);
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      filters: getColumnFilterValues(transactions, "id").map((id) => ({ text: id.toString(), value: id })),
      onFilter: (value: number, record: Transactionprops) => record.id === value,
    },
    {
      title: "Header",
      dataIndex: ["subheader", "header", "name"],
      key: ["subheader", "header", "name"],
      filters: getColumnFilterValues(transactions, ["subheader", "header", "name"]).map((name) => ({ text: name, value: name })),
      onFilter: (value: string, record: Transactionprops) => record.subheader.header.name === value,
    },
    {
      title: "Subheader",
      dataIndex: ["subheader", "name"],
      key: ["subheader", "name"],
      filters: getColumnFilterValues(transactions, ["subheader", "name"]).map((name) => ({ text: name, value: name })),
      onFilter: (value: string, record: Transactionprops) => record.subheader.name === value,
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (text: string) => dayjs(text).format("MM-DD-YYYY"),
      filters: getColumnFilterValues(transactions, "date").map((date) => ({ text: date, value: date })),
      onFilter: (value: string, record: Transactionprops) => record.date === value,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      filters: getColumnFilterValues(transactions, "amount").map((amount) => ({ text: amount.toString(), value: amount })),
      onFilter: (value: number, record: Transactionprops) => record.amount === value,
    },
    {
      title: "Actions",
      key: "actions",
      render: (text: string, record: Transactionprops) => (
        <span>
          <a onClick={() => handleEdit(record)}>Edit</a>
          <span> | </span>
          <a onClick={() => handleDelete(record.id)}>Delete</a>
        </span>
      ),
    },
  ];
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const filteredData = transactions.filter((record) =>
    Object.values(record).some((value) =>
      value && value.toString().toLowerCase().includes(searchQuery.toLowerCase())
    )
  );



  return (
    <div className="card">
      <div className="cardHeader">
        <h2 className="headContent">Transactions</h2>
        <div className="rightContent">
        <Input
              placeholder="Search..."
              onChange={handleSearch}
              style={{ marginRight: '10px' }}
            />
 {userRole === 'Admin' && (
          <Select
            placeholder="Select Franchisee"
            style={{ width: 200 }}
            onChange={handleChangeFranchisee}
            value={selectedFranchisee}
          >
            {franchisee.map((fr) => (
              <Option key={fr.id} value={fr.id}>
                {fr.name}
              </Option>
            ))}
          </Select>
 )}
          <Button onClick={() => setFormVisible(true)}>Add Transaction</Button>
        </div>
      </div>
      <br />
      <Table
        className="custom-table"
        dataSource={filteredData}
        columns={columns as any}
        pagination={{ pageSize: 20 }}
        size="small"
        loading={fetching}
      />
      <AddTransactionForm
        onSuccessCallBack={() => {
          setFormVisible(false);
          gettransactions(setTransactions, setFetching, selectedFranchisee);
        }}
        isVisible={formVisible}
        onClose={() => setFormVisible(false)}
        editingTransaction={editingTransaction}
        onCloseWithoutEditing={onCloseWithoutEditing}
      />
    </div>
  );
};

export default Transactions;