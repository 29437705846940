import React, { FC, useState, useEffect } from "react";
import { Button, Table, Modal, notification, Select } from "antd";
import { useGetProduct, useGetCategory, useGetCurrency, useGetUnitofmeasure } from "../utils/hooks";
import { AuthTokenType, product, unitofmeasure, category, currency, DataProps } from "../utils/types";
import { getAuthToken, getProduct } from "../utils/functions";
import AddProductForm from "../components/AddProductForm";
import axios from "axios";
import { productsUrl } from "../utils/network";
import ContentLayout from "../components/ContentLayout";



const Products: FC = () => {
  const { Option } = Select;

  const [expandedImage, setExpandedImage] = useState<string | undefined>(undefined); // State for expanded image

  // Function to handle image click and expand
  const handleImageExpand = (imageUrl: string) => {
    setExpandedImage(imageUrl);
  };
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Image",
      dataIndex: "image",
      key: "image",
      render: (image: string) => (
        image ? <img onClick={() => handleImageExpand(image)} src={image} alt="Product" style={{width:"4vw" ,height:"4vh"}} /> : "No Image Available"
      ),
    },    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "SKU Weight",
      dataIndex: "sku_weight",
      key: "sku_weight",
    },
    {
      title: "SKU Weight UOM",
      dataIndex: "weight_uom",
      key: "weight_uom",
    },
    {
      title: "SKU Length",
      dataIndex: "sku_length",
      key: "sku_length",
    },
    {
      title: "SKU Length UOM",
      dataIndex: "length_uom",
      key: "length_uom",
    },
    {
      title: "SKU Height",
      dataIndex: "sku_height",
      key: "sku_height",
    },
    {
      title: "SKU Height UOM",
      dataIndex: "height_uom",
      key: "height_uom",
  
    },
    {
      title: "Color",
      dataIndex: "color",
      key: "color",
    },
    {
      title: "SKU Description",
      dataIndex: "sku_description",
      key: "sku_description",
    },
    {
      title: "SKU UOM",
      dataIndex: "uom",
      key: "uom",
    },
    {
      title: "SKU Buy Cost",
      dataIndex: "sku_buy_cost",
      key: "sku_buy_cost",
    },
    {
      title: "SKU Price",
      dataIndex: "sku_price",
      key: "sku_price",
    },
    {
      title: "Category",
      dataIndex: "cat",
      key: "cat",
    },

    {
      title: "Sub Category",
      dataIndex: "subcat",
      key: "subcat",
    },
    {
      title: "Currency",
      dataIndex: "curr",
      key: "curr",
    },
    {
      title: "Actions",
      key: "actions",
      render: (text: string, record: product) => (
        <span>
          <a onClick={() => handleEdit(record)}>Edit</a>
          <span> | </span>
          <a onClick={() => handleDelete(record.id)}>Delete</a>
        </span>
      ),
    },
  ];

  const [products, setProducts] = useState<product[]>([]);
  const [fetching, setFetching] = useState<boolean>(true);
  const [formVisible, setFormVisible] = useState(false);
  const [editingProduct, setEditingProduct] = useState<product | null>(null);



  useGetProduct(setProducts, setFetching);

  useEffect(() => {
    // When editingProduct changes, open the form
    if (editingProduct) {
      setFormVisible(true);
    }
  }, [editingProduct]);

  const handleEdit = (record: product) => {
    setEditingProduct(record);
  };
  const onCloseWithoutEditing = () => {
    setEditingProduct(null); // Reset editingCategory when form is closed without editing
  };

  const handleDelete = (productId: number) => {
    Modal.confirm({
      title: "Confirm Deletion",
      content: "Are you sure you want to delete this product?",
      onOk: async () => {
        try {
          const headers = getAuthToken() as AuthTokenType;

          // Make a DELETE request to the API endpoint for deleting a product
          await axios.delete(`${productsUrl}/${productId}/`, headers);

          // After successful deletion, fetch updated products
          getProduct(setProducts, setFetching);
        } catch (error) {
          // Handle error if the deletion fails
          notification.error({
            message: "Delete Operation Error",
          });
        }
      },
      onCancel: () => {
        // Do nothing if the user cancels the deletion
      },
      okText: "Yes",
      cancelText: "No",
    });
  };

  const onCreateProduct = () => {
    setFormVisible(true);
    setEditingProduct(null); // Reset editingProduct for new product
  };

  return (
    <ContentLayout
      pageTitle="Products"
      buttontitle="Add Product"
      setModalState={setFormVisible}
      dataSource={products as any}
      columns={columns as any}
      fetching={fetching}
      includeFilters={true}
    >
            {/* Image Modal */}
            <Modal
        visible={!!expandedImage}
        onCancel={() => setExpandedImage(undefined)}
        footer={null}
        closable={false}
      >
        <img src={expandedImage || ''} alt="Expanded" style={{ width: "100%" }} />
      </Modal>

      <AddProductForm
        onSuccessCallBack={() => {
          setFormVisible(false);
          getProduct(setProducts, setFetching);
        }}
        isVisible={formVisible}
        onClose={() => setFormVisible(false)}
        editingProduct={editingProduct}
        onCloseWithoutEditing={onCloseWithoutEditing}
      />
    </ContentLayout>
    
  );
  
};

export default Products;