import React, { useEffect, useState } from 'react';
import { Tabs, Table, Spin, Input, Select, Button } from 'antd';
import { FranchiseeProps, HeaderProfitLossprops, ProfitLossStatementprops } from '../utils/types';
import { useGetFranchisee, useGetSankey, useGetheaderprofitloss, useGetprofitloss } from '../utils/hooks';
import { getheaderprofitloss, getprofitloss } from '../utils/functions';
import Sankeychart from '../components/Dashboard/Sankeychart';

const { TabPane } = Tabs;
const { Option } = Select;

const ProfitLossComponent: React.FC = () => {


  const userRole = localStorage.getItem('token1');


  const [headerProfitLoss, setHeaderProfitLoss] = useState<HeaderProfitLossprops[]>([]);
  const [profitLoss, setProfitLoss] = useState<ProfitLossStatementprops[]>([]);
  const [fetchingHeaderProfitLoss, setFetchingHeaderProfitLoss] = useState<boolean>(true);
  const [fetchingProfitLoss, setFetchingProfitLoss] = useState<boolean>(true);
  const [selectedMonth, setSelectedMonth] = useState<number | string>('All');
  const [selectedYear, setSelectedYear] = useState<number | string>('All');
  const [fetching, setFetching] = useState<boolean>(true);

  const [selectedTab, setSelectedTab] = useState<string>('1'); 

  const [franchisee, setfranchisee] = useState<FranchiseeProps[]>([]);
  useGetFranchisee(setfranchisee, setFetching);
  const [selectedFranchisee, setSelectedFranchisee] = useState<string | null>(null); // State to hold selected franchisee
  const handleChangeFranchisee = (value: string) => {
    setSelectedFranchisee(value); // Update selected franchisee when filter changes
  };


  useGetheaderprofitloss(setHeaderProfitLoss, setFetchingHeaderProfitLoss,selectedYear,selectedMonth,selectedFranchisee);
  useGetprofitloss(setProfitLoss, setFetchingProfitLoss,selectedYear,selectedMonth,selectedFranchisee);

  useEffect(() => {
    getheaderprofitloss(setHeaderProfitLoss, setFetchingHeaderProfitLoss,selectedYear,selectedMonth,selectedFranchisee);
    getprofitloss(setProfitLoss, setFetchingProfitLoss,selectedYear,selectedMonth,selectedFranchisee);
  },[selectedYear,selectedMonth,selectedFranchisee])

  const handleMonthChange = (value: number | string) => {
    setSelectedMonth(value);
  };


  const handleYearChange = (value: number | string) => {
    setSelectedYear(value);
  };

  const headerProfitLossColumns = [
    {
      title: 'Month',
      dataIndex: 'month',
      key: 'month',
    },
    {
      title: 'Year',
      dataIndex: 'year',
      key: 'year',
    },
    {
      title: 'Header',
      dataIndex: 'header',
      key: 'header',
      render: (header: HeaderProfitLossprops['header']) => header.name,
    },
    {
      title: 'Subheader',
      dataIndex: 'subheader',
      key: 'subheader',
      render: (subheader: HeaderProfitLossprops['subheader']) => subheader.name,
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
    },
  ];

  const profitLossColumns = [
    {
      title: 'Month',
      dataIndex: 'month',
      key: 'month',
    },
    {
      title: 'Year',
      dataIndex: 'year',
      key: 'year',
    },
    {
      title: 'Total Income',
      dataIndex: 'total_income',
      key: 'total_income',
    },
    {
      title: 'Total Expense',
      dataIndex: 'total_expense',
      key: 'total_expense',
    },
    {
      title: 'Overall Profit',
      dataIndex: 'overall_profit',
      key: 'overall_profit',
    },
  ];

  const handleExportToCSV = (columns: any[], data: any[]) => {
    const csvContent = columns.map(column => column.title).join(',') + '\n' +
      data.map(row => columns.map(column => {
        const value = row[column.dataIndex as string];
        if (value && typeof value === 'object' && value.name) {
          return `"${value.name}"`;
        }
        return `"${value}"`;
      }).join(',')).join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", "data.csv");

    document.body.appendChild(link);
    link.click();

    window.URL.revokeObjectURL(url);
  };

  const handleTabChange = (key: string) => {
    setSelectedTab(key); // Update the selected tab state
  };

  

  return (
    <div className="card">
      <div className="cardHeader">
        <h2 className="headContent">Statements</h2>
        <div className="rightContent">
          <Input placeholder="Search..." style={{ marginRight: 10 }} />
          {userRole === 'Admin' && (
          <Select
            placeholder="Select Franchisee"
            style={{ width: 200, marginRight: 10 }}
            onChange={handleChangeFranchisee}
            value={selectedFranchisee}
          >
            {franchisee.map((fr) => (
              <Option key={fr.id} value={fr.id}>
                {fr.name}
              </Option>
            ))}
          </Select>
          )}
          <Select
            placeholder="Select Month"
            onChange={handleMonthChange}
            style={{ width: 120, marginRight: 10 }}
            allowClear
          >
            <Option value="All">All</Option>
            {Array.from({ length: 12 }, (_, i) => i + 1).map(month => (
              <Option key={month} value={month}>{month}</Option>
            ))}
          </Select>
          <Select
            placeholder="Select Year"
            onChange={handleYearChange}
            style={{ width: 120 }}
            allowClear
          >
            <Option value="All">All</Option>
            {Array.from({ length: 5 }, (_, i) => new Date().getFullYear() - i).map(year => (
              <Option key={year} value={year}>{year}</Option>
            ))}
          </Select>
          <Button className='bbutton' onClick={() => handleExportToCSV(selectedTab === '1' ? headerProfitLossColumns : profitLossColumns, selectedTab === '1' ? headerProfitLoss : profitLoss)} >
            Download CSV
          </Button>
        </div>
      </div>
      <Tabs activeKey={selectedTab} onChange={handleTabChange}>
        <TabPane tab="Details by Headers" key="1">
          {fetchingHeaderProfitLoss ? (
            <Spin />
          ) : (
            <Table
              className="custom-table"
              dataSource={headerProfitLoss}
              columns={headerProfitLossColumns}
              rowKey={(record) => `${record.header.id}-${record.subheader.id}`}
              pagination={false}
            />
          )}
        </TabPane>
        <TabPane tab="Overall Profit Loss" key="2">
          {fetchingProfitLoss ? (
            <Spin />
          ) : (
            <Table
              className="custom-table"
              dataSource={profitLoss}
              columns={profitLossColumns}
              rowKey={(record) => `${record.year}-${record.month}`}
              pagination={false}
            />
          )}
        </TabPane>
        <TabPane tab="Analysis" key="3">
        <Sankeychart
            month={selectedMonth}
            year={selectedYear}
            franchisee={selectedFranchisee}
          />
          </TabPane>
      </Tabs>
    </div>
  );
};

export default ProfitLossComponent;