import React, { FC, useEffect, useState } from "react";
import { Drawer, notification, Form, Input, Select, Button } from "antd";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import { AuthTokenType, DataProps, AddFranchiseeFormModalProps, UserProps } from "../utils/types";
import { getAuthToken } from "../utils/functions";
import axios, { AxiosResponse } from "axios";
import { CreateUserUrl, FranchiseeUrl, UsersUrl } from "../utils/network";

const { Option } = Select;

interface AddFranchiseeFormProps extends AddFranchiseeFormModalProps {
  editingUser?: UserProps | null;
}

const AddFranchiseeForm: FC<AddFranchiseeFormProps> = ({
  isVisible = false,
  onSuccessCallBack,
  onClose,
  editingFranchisee,
  onCloseWithoutEditing, // new prop

}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [publickVisible, setpublickVisible] = useState(false);
  const [secretkVisible, setsecretkVisible] = useState(false);

  const [tauthvisible, settauthvisible] = useState(false);

  const handleFormClose = () => {
    form.resetFields();
    onClose?.();
    if (!form.isFieldsTouched()) {
      onCloseWithoutEditing?.(); // Notify the parent about closing without editing
    }
  };

  useEffect(() => {
    if (editingFranchisee) {
      form.setFieldsValue(editingFranchisee);
    } else {
      form.resetFields();
    }
  }, [editingFranchisee, form]);

  const onSubmit = async (values: DataProps) => {
    setLoading(true);
    const headers = getAuthToken() as AuthTokenType;

    try {
      let response: AxiosResponse;

      if (editingFranchisee) {
        // Editing user
        response = await axios.put(`${FranchiseeUrl}/${editingFranchisee.id}`, values, headers);
      } else {
        // Adding new user
        response = await axios.post(FranchiseeUrl, values, headers);
      }

      setLoading(false);

      if (response) {
        onSuccessCallBack?.();
        onClose?.(); // Close the drawer
      }
    } catch (error) {
      notification.error({
        message: "Operation Error",
      });
      setLoading(false);
    }
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };


  const toggletauthvisible = () => {
    settauthvisible(!tauthvisible);
  };


  const togglepublickVisibility = () => {
    setpublickVisible(!publickVisible);
  };

  const togglesecretkVisibility = () => {
    setsecretkVisible(!secretkVisible);
  };


  return (
    <Drawer
      title={editingFranchisee ? "Edit Franchisee" : "Add Franchisee"}
      visible={isVisible}
      onClose={handleFormClose}
      destroyOnClose
      width={360}
    >
      <Form layout="vertical" onFinish={onSubmit} form={form} onValuesChange={() => setHasChanges(true)}>
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, message: 'Please input the name!' }]}
        >
          <Input placeholder="Name" />
        </Form.Item>
        <Form.Item
          label="Phone"
          name="phone"
          rules={[{ required: true, message: 'Please input the Phone!' }]}
        >
          <Input placeholder="Phone" />
        </Form.Item>
        <Form.Item
          label="State"
          name="state"
          rules={[{ required: true, message: 'Please input the state!' }]}
        >
          <Input placeholder="State" />
        </Form.Item>
        <Form.Item
          label="Host User Email"
          name="host_user"
          rules={[{ required: true, message: 'Please input the Host User!' }]}
        >
          <Input placeholder="Host User" />
        </Form.Item>
        <Form.Item
          label="Host Password"
          name="host_pass"
          rules={[{ required: true, message: 'Please input the Host Pass!' }]}
        >
          <Input
            type={passwordVisible ? 'text' : 'password'}
            placeholder="Host Password"
            suffix={
              <Button
                icon={passwordVisible ? <EyeInvisibleOutlined /> : <EyeOutlined />}
                onClick={togglePasswordVisibility}
                type="text"
              />
            }
          />
                  </Form.Item>


              <Form.Item
          label="Stripe Public Key"
          name="stripe_publick"
          rules={[{ required: true, message: 'Please input the Stripe Public Key!' }]}
        >
          <Input
            type={publickVisible ? 'text' : 'password'}
            placeholder="Public Key"
            suffix={
              <Button
                icon={publickVisible ? <EyeInvisibleOutlined /> : <EyeOutlined />}
                onClick={togglepublickVisibility}
                type="text"
              />
            }
          />
        </Form.Item>
        <Form.Item
          label="Stripe Secret Key"
          name="stripe_secretk"
          rules={[{ required: true, message: 'Please input the Stripe Secret Key!' }]}
        >
          <Input
            type={secretkVisible ? 'text' : 'password'}
            placeholder="Secret Key"
            suffix={
              <Button
                icon={secretkVisible ? <EyeInvisibleOutlined /> : <EyeOutlined />}
                onClick={togglesecretkVisibility}
                type="text"
              />
            }
          />
        </Form.Item>
        <Form.Item
          label="Stripe Webhook"
          name="stripe_webhook"
          rules={[{ required: true, message: 'Please input the Stripe Webhook!' }]}
        >
          <Input
            placeholder="Webhook"
           />
        </Form.Item>
        <Form.Item
          label="Twilio Account SID"
          name="twilio_account_sid"
          rules={[{ required: true, message: 'Please input the Twilio Account SID!' }]}
        >
          <Input
            placeholder="Account SID"
           />
        </Form.Item>
        <Form.Item
          label="Twilio Auth Token"
          name="twilio_auth_token"
          rules={[{ required: true, message: 'Please input the Twilio Auth Token!' }]}
        >
          <Input
            type={tauthvisible ? 'text' : 'password'}
            placeholder="Auth Token"
            suffix={
              <Button
                icon={tauthvisible ? <EyeInvisibleOutlined /> : <EyeOutlined />}
                onClick={toggletauthvisible}
                type="text"
                />
              }
            />         
        </Form.Item>
        <Form.Item
          label="Twilio Phone Number"
          name="twilio_phone_number"
          rules={[{ required: true, message: 'Please input the Twilio Phone Number!' }]}
        >
          <Input
            placeholder="Phone Number"
           />
        </Form.Item>
        <Form.Item
          label="License Number"
          name="licensenumber"
          rules={[{ required: false }]}
        >
          <Input
            placeholder="License Number"
           />
        </Form.Item>

        
        <Form.Item>
          <Button htmlType="submit" type="primary" block loading={loading}>
            {editingFranchisee ? "Update" : "Submit"}
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default AddFranchiseeForm;