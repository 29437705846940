import React from 'react';
import { Page, Text, View, Document, StyleSheet, PDFDownloadLink, Image } from '@react-pdf/renderer';
import logo from '../assets/companylogo.png';
import { estimatesProps } from '../utils/types';

function formatDate(dateString:any) {
  // Assuming dateString is in ISO 8601 format, e.g., "2024-06-18T00:00:00Z"
  const date = new Date(dateString);
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero based
  const year = date.getFullYear();

  return `${month}-${day}-${year}`;
}
const styles = StyleSheet.create({

  signatureBox: {
    width: 120,
    height: 40,
    borderWidth: 1,
    borderColor: '#000',
    marginTop: 5,
  },
  page: {
    padding: 10,
    fontSize: 8,
    fontFamily: 'Helvetica',
    border: '1px solid #000',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 5,
    borderBottom: '1px solid #000',
    paddingBottom: 10,
  },
  logo: {
    width: 120,
    height: 80,
  },
  companyInfo: {
    textAlign: 'left',
    flex: 1,
    fontSize: 14,
    fontWeight: 'bold',
  },
  section: {
    marginBottom: 5,
    border: '1px solid #000',
    padding: 5,
  },
  title: {
    fontSize: 10,
    marginBottom: 5,
    borderBottom: '1px solid #000',
    paddingBottom: 5,
    fontWeight: 'bold',
  },
  label: {
    fontWeight: 'bold',
  },
  list: {
    marginTop: 5,
  },
  listItem: {
    marginBottom: 4,
  },
  footer: {
    marginTop: 5,
    textAlign: 'center',
    border: '1px solid #000',
    padding: 10,
    fontSize: 7,
  },
  signature: {
    width: 100,
    height: 40,
    border: '1px solid #000',
    marginTop: 10,
  },
  estimateImage: {
    width: '100%',
    height: 100,
    marginTop: 10,
    border: '1px solid #000',
  },
  contentRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 6,
  },
  leftColumn: {
    flex: 1,
    marginRight: 3,
  },
  rightColumn: {
    flex: 1,
    marginLeft: 3,
  },
  paymentRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 6,
  },
  paymentItem: {
    flex: 1,
    marginRight: 6,
  },
  paymentItemFull: {
    flex: 1,
    marginRight: 6,
    marginBottom: 6,
  },
  customerInfoRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 4,
  },
  customerInfoItem: {
    flex: 1,
    marginRight: 6,
  },
  signatureRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 10,
    alignItems: 'center',
  },
  
  signatureColumn: {
    flex: 1,
    marginRight: 3,
    alignItems: 'center',
  },
  fullPageImage: {
    width: '100%',
    height: '100%',
  },
  approvalColumn: {
    flex: 1,
    marginLeft: 3,
    textAlign: 'center',
  },
});

interface EstimateDocumentProps {
  estimate: estimatesProps;
}

const EstimateDocument: React.FC<EstimateDocumentProps> = ({ estimate }) => (
  <Document>
    <Page style={styles.page}>
      <View style={styles.header}>
        <Image src={logo} style={styles.logo} />
        <View style={styles.companyInfo}>
          <Text>A SAFE POOL</Text>
          <Text>{estimate.franchisee.name} | {estimate.franchisee.phone} | ASafePool.com</Text>
        </View>
      </View>

      <View style={styles.contentRow}>
        <View style={styles.leftColumn}>
          <View style={styles.section}>
            <Text style={styles.title}>Customer Information</Text>
            <View style={styles.customerInfoRow}>
              <View style={styles.customerInfoItem}>
                <Text><Text style={styles.label}>Name:</Text> {estimate.lead?.name}</Text>
                <Text><Text style={styles.label}>Address:</Text> {estimate.lead?.address}</Text>
                <Text><Text style={styles.label}>City:</Text> {estimate.lead?.city}</Text>
                <Text><Text style={styles.label}>State:</Text> {estimate.lead?.state}</Text>
              </View>
              <View style={styles.customerInfoItem}>
                <Text><Text style={styles.label}>Primary Phone:</Text> {estimate.lead?.phone}</Text>
                <Text><Text style={styles.label}>Email:</Text> {estimate.lead?.email}</Text>
              </View>
            </View>
            <Text style={{ marginTop: 10 }}><Text style={styles.label}>Special Instructions:</Text> {estimate.specialinstructions}</Text>
            <Text>
  <Text style={styles.label}>Completion Date:</Text> {formatDate(estimate.completiondate)}
</Text>
                <Text><Text style={styles.label}>Preferred Installation Day:</Text> {estimate.preferredinstallday}</Text>
                <Text><Text style={styles.label}>Preferred Installation Time:</Text> {estimate.preferredstarttime}</Text>
 
          </View>
        </View>

        <View style={styles.rightColumn}>
          <View style={styles.section}>
            <Text style={styles.title}>Estimate Details</Text>
            <Text style={styles.label}>Estimated Materials:</Text>
            <View style={styles.list}>
              {estimate.estimate_items?.map((item: any, index: number) => (
                <Text key={index} style={styles.listItem}>{item.item.name}: {item.quantity} units @ {item.item.color} - {item.item.sku_height}H - {item.item.sku_length}L</Text>
              ))}
            </View>
          </View>
        </View>
      </View>

      <View style={styles.section}>
        <Text style={styles.title}>Payment Details</Text>
        <View style={styles.paymentRow}>
          <View style={styles.paymentItemFull}>
            <Text><Text style={styles.label}>Total Price:</Text> {estimate.totalprice}</Text>
          </View>
          <View style={styles.paymentItemFull}>
            <Text><Text style={styles.label}>Mode:</Text> {estimate.mode}</Text>
          </View>
        </View>
      </View>

      <View style={styles.section}>
        <View style={styles.signatureRow}>
          <View style={styles.signatureColumn}>
            <Text style={styles.title}>Customer Signature</Text>
            <Image src={`${estimate.signature}`} style={styles.signature} />
          </View>
          <View style={styles.signatureColumn}>
      <Text style={styles.title}>Estimator Approval</Text>
      <Image src={`${estimate.estimator_signature}`} style={styles.signature} />

    </View>
  </View>
</View>


      <View style={styles.footer}>
        <Text>WARNING: This safety barrier may not, by itself, prevent a pool accident and is not a substitute for adult supervision of children.</Text>
        <Text>CUSTOMER UNDERSTANDS THAT: I, Seller is hereby granted access to the above mentioned property for the purpose of installing / removing the safety barrier described above. 2. Seller is not responsible for damage to
        any objects, including water, electric, gas lines and pool plumbing located less than 18” below the surface of the deck or that do not meet current building codes and regulations. 3. Seller is not responsible for residual sediment
        found on the above mentioned surface due to lack of drainage. 4. Seller bears no responsibility for damage to the barrier caused by misuse, abuse, alteration, neglect, accidents, animals, or acts of God. 5. Title to this safety
        barrier remains with Seller until above price is fully paid. 6. Customer agrees to purchase the above mentioned safety barrier product at the price as stated. 7. Pool safety barriers, by their very nature, prohibit unobstructed
        acces to the pool. Removal and replacement may require 8 to 10 minutes or more. 8. Although every effort is made to cut clean and precise holes, it is possible that chips may occur around perimeter of holes. 9. All safety
        barriers are custom-made and installed and therefore refunds/returns are not allowed. 10. Customer agrees to have Seller purchase all materials needed for contracted job. 11. Buyer understands that by law, Seller must collect
        sales tax for all jobs sold and installed . Contractor is not liable for any damage caused while drilling on to any post tension slab that is not clearly marked as post tension. A Safe Pool products may or
        may not meet your city’s code requirements, customer to verify.Credit Card fee of 3.5% applied to all transactions.
        Licensed | Bonded | Insured - {estimate.franchisee.licensenumber || ""}
 </Text>
      </View>
    </Page>
    {estimate.estimate_image && (
      <Page style={{ padding: 0 }}>
        <Image src={`data:image/png;base64,${estimate.estimate_image}`} style={{ width: 'auto', height: '100%', objectFit: 'contain' }} />
      </Page>
    )}

    {estimate.franchisee.state === 'arizona' && (
      <Page style={styles.page}>
        <Text>This is a blank page for Arizona.</Text>
      </Page>
    )}
  </Document>
);

const EstimateGenerator: React.FC<{ estimate: estimatesProps }> = ({ estimate }) => {
  return (
    <div className="estimate-pdf">
      <PDFDownloadLink
        document={<EstimateDocument estimate={estimate} />}
        fileName={`Estimate_${estimate.id}.pdf`}
      >
        {({ blob, url, loading, error }) =>
          loading ? 'Generating PDF...' : 'Download PDF'
        }
      </PDFDownloadLink>
    </div>
  );
};

export default EstimateGenerator;