import React, { useState } from "react";
import { notification, Input, Modal, Spin } from "antd";
import axios from "axios";
import { getAuthToken } from "../utils/functions";
import { paymentsUrl } from "../utils/network";
import { AuthTokenType } from "../utils/types";

const StripePaymentLink: React.FC<{ salesId: number }> = ({ salesId }) => {
  const [amount, setAmount] = useState<number | "">("");
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAmount(Number(e.target.value));
  };

  const handlePayClick = async () => {
    if (!amount || amount <= 0) {
      notification.error({
        message: "Invalid Amount",
        description: "Please enter a valid amount.",
      });
      return;
    }

    setLoading(true);

    try {
      const headers = getAuthToken() as AuthTokenType;
      const response = await axios.post(
        `${paymentsUrl}/create_checkout_session/`,
        { sales_id: salesId, amount: amount },
        headers
      );

      if (response.data.checkout_url) {
        // Simulate sending the link to the customer via email on the backend
        notification.success({
          message: "Payment Link Shared",
          description: "The payment link has been shared with the customer via email.",
        });
        setModalVisible(false); // Close the modal after successful link sharing
      } else {
        notification.error({
          message: "Payment Error",
          description: "There was an error creating the payment link.",
        });
      }
    } catch (error) {
      notification.error({
        message: "Payment Error",
        description: "There was an error creating the payment intent.",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ display: "inline-block" }}>
      <span
        style={{ color: "blue", cursor: "pointer" }}
        onClick={() => setModalVisible(true)}
      >
        Send Link
      </span>

      <Modal
        title="Enter Amount"
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        onOk={handlePayClick}
        okText="Proceed"
        cancelText="Cancel"
        okButtonProps={{ loading: false }} // Do not apply loading to the "Proceed" button
      >
        <Input
          type="number"
          value={amount}
          onChange={handleAmountChange}
          placeholder="Enter amount"
        />

        {loading && (
          <div style={{ textAlign: "center", marginTop: 20 }}>
            <Spin size="large" />
          </div>
        )}
      </Modal>
    </div>
  );
};

export default StripePaymentLink;